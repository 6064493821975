import React from "react";
import { useHistory } from "react-router-dom";

function SearchResult({ resultName, setShowSearch }) {
  const history = useHistory();
  const link = resultName.toLowerCase().replace(/\s+/g, "-");

  const handleClick = () => {
    setShowSearch(false);
    history.push(`/tea/${link}`);
  };

  return (
    <div className="searchResult">
      <p onClick={handleClick}>{resultName}</p>
    </div>
  );
}

export default SearchResult;
