import React from "react";
import volunteers from "../../images/volunteers.jpg";
import elephant from "../../images/elephant.jpg";
import hands from "../../images/hands.jpg";
import "./GivingBackRight.css";

function GivingBackRight() {
  return (
    <div className="givingBackRight">
      <img src={elephant} alt="" className="gbImg1 gbImgSecondary" />
      <img src={hands} alt="" className="gbImg2 gbImgMain" />
      <img src={volunteers} alt="" className="gbImg3 gbImgSecondary" />
    </div>
  );
}

export default GivingBackRight;
