import React, { useState, useEffect } from "react";
import Product from "../../components/product/Product";
import { db } from "../../data/firebase";
import { is_touch_device } from "../../utils/helperFunctions";
import "./Products.css";

function Products({ category }) {
  const [products, setProducts] = useState([]);
  const [touch, setTouch] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      db.collection("products").onSnapshot((snapshot) => {
        setProducts(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
      return () => (isMounted = false);
    }
  }, []);

  useEffect(() => {
    setTouch(is_touch_device());
    return () => {};
  }, []);

  return (
    <div className="products">
      {products.map(
        ({ id, data: { name, priceUSD, weight, image, type } }) =>
          type.includes(category) && (
            <Product
              touch={touch}
              key={id}
              id={id}
              name={name}
              price={priceUSD}
              weight={weight}
              image={image}
            />
          )
      )}
    </div>
  );
}

export default Products;
