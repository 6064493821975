import React from "react";
import "./GivingBackLeft.css";

function GivingBackLeft() {
  return (
    <div className="givingBackLeft">
      <div className="gbTitleContainer">
        <h2 className="gbTitle">Giving Back</h2>
      </div>

      <p className="gbText">
        We at The Happy Teacup believe it is our responsiblity to use our
        platform for good. We therefore are commited to donating 10% of all our
        profits to charitable organizations that align with our values. We are
        still a relatively small store, but hope that as sales increase, we will
        be able to raise our donations to 15% percent or more.
      </p>

      <div className="gbText">
        At the moment, we are supporting Lighthouse, a Japanese non-profit
        organisation working to eliminate human trafficking, especially sex
        trafficking, in Japan. <br />
        <br />
        Based in Tokyo, Lighthouse fights against human trafficking by: <br />
        <ul>
          <li>
            Providing confidential consultation services for survivors of human
            trafficking.
          </li>
          <li>
            Educating and training law enforcement and government officials to
            recognize human trafficking survivors and investigate cases.
          </li>
          <li>
            Organizing awareness campaigns and seminars to help bring more
            attention to the issue of human trafficking and encourage change in
            society.{" "}
          </li>
          <li>Lobbying the government to change legislation</li>
        </ul>
        To learn more about Lighthouse, you can visit their website{" "}
        <a className="gbLink" href="https://lhj.jp/english/index.html">
          here
        </a>
        .
      </div>

      <p className="gbText">
        {" "}
        If you are involved with or know of a non-profit organisation that
        aligns with our values of love, kindness, and compassion for all living
        beings, we'd love to hear from you. To get in touch, please click the
        link in the footer.{" "}
      </p>
    </div>
  );
}

export default GivingBackLeft;
