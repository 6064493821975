import React from "react";
import "./AboutTop.css";
import mountains from "../../images/mountains.jpg";
import holdingTea from "../../images/holdingTea.jpg";
import holdingLeaves from "../../images/holdingLeaves.jpg";
import leaf from "../assets/leaf.png";
import cinnamon from "../assets/cinamon.png";

function AboutTop() {
  return (
    <div className="aboutTop">
      <img
        src={holdingLeaves}
        alt=""
        className="aboutTopImg1 aboutTopSecondaryImg"
      />
      <img src={mountains} alt="" className="aboutTopImg2 aboutTopMainImg" />
      <img
        src={holdingTea}
        alt=""
        className="aboutTopImg3 aboutTopSecondaryImg"
      />

      <img src={cinnamon} alt="" className="aboutTopAbsImg aboutCinnamon" />
      <img src={leaf} alt="" className="aboutTopAbsImg aboutLeaf" />
    </div>
  );
}

export default AboutTop;
