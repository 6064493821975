import React, { useState } from "react";
import "./CartProduct.css";
import Counter from "./../../components/counter/Counter";
import { useDispatch } from "react-redux";
import {
  removeFromCart,
  downQuantity,
  upQuantity,
} from "./../../data/cartSlice";

function CartProduct({ image, name, quantity, price, rate, id, formatter }) {
  const dispatch = useDispatch();

  const [cartProductQuantity, setCartProductQuantity] = useState(quantity);

  const handleRemoveFromCart = () => {
    dispatch(removeFromCart(id));
  };

  const qutUp = () => {
    dispatch(
      upQuantity({
        id: id,
        ammount: 1,
      })
    );
    setCartProductQuantity(cartProductQuantity + 1);
  };

  const qutDown = () => {
    if (cartProductQuantity > 1) {
      dispatch(
        downQuantity({
          id: id,
          ammount: 1,
        })
      );
      setCartProductQuantity(cartProductQuantity - 1);
    }
  };
  return (
    <div className="cartProduct">
      <img src={image} alt={name} className="cartProduct__image" />
      <div className="cartProduct__right">
        <h3 className="cartProduct__title">{name}</h3>
        <div className="cartProduct__quantityPrice">
          <div className="cartProduct__quantity">
            <Counter
              quantity={cartProductQuantity}
              handleClickPlus={qutUp}
              handleClickMinus={qutDown}
            />
            <p className="cartProduct__delete" onClick={handleRemoveFromCart}>
              delete
            </p>
          </div>
          <p className="cartProduct__priceTotal">
            {formatter.format(price * cartProductQuantity * rate)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CartProduct;
