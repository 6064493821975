import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { selectProducts } from "../../data/productsSlice";
import { useSelector } from "react-redux";
import "./searchBar.css";
import { arrayRemove } from "../../utils/helperFunctions";
import SearchResult from "./SearchResult";

function SearchBar({
  showSearch,
  setShowSearch,
  searchBarClass,
  setSearchBarClass,
}) {
  const products = useSelector(selectProducts);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);

  const toggleSearch = () => {
    if (showSearch === false) {
      setSearchBarClass("searchBar__wrapper0");
      setShowSearch(true);
      setTimeout(() => setSearchBarClass("searchBar__wrapper"), 10);
    } else if (showSearch === true) {
      setSearchBarClass("searchBar__wrapper0");
      setTimeout(() => setShowSearch(false), 500);
    }
  };

  const searchForMatch = (e) => {
    setSearchTerm(e.target.value);
    products.forEach((product) => {
      if (
        product.data.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.data.description
          .toLowerCase()
          .includes(searchTerm.toLowerCase) ||
        product.data.ingredients.toLowerCase().includes(searchTerm.toLowerCase)
      ) {
        //checking if product id already in results array
        let isInArray = false;
        if (results.length > 0) {
          results.forEach((resultName) => {
            if (resultName === product.data.name) {
              isInArray = true;
            }
          });
        }
        //adding product id to results array

        if (isInArray === false) {
          setResults([...results, product.data.name]);
        }
      } else {
        //checking if product id is in results array

        results.forEach((result) => {
          if (result === product.data.name) {
            setResults(arrayRemove(results, result));
          }
        });
      }
    });
  };

  return (
    <div className="searchBar">
      {showSearch && (
        <div className={searchBarClass}>
          <div className="searchBar__input">
            <SearchIcon className="searchIcon" />
            <input
              placeholder="Search"
              value={searchTerm}
              onChange={searchForMatch}
            />
          </div>
          <div className="searchBar__results">
            {results.map((result) => (
              <SearchResult
                resultName={result}
                key={result}
                setShowSearch={setShowSearch}
              />
            ))}
          </div>
        </div>
      )}
      <SearchIcon className="header__option" onClick={toggleSearch} />
    </div>
  );
}

export default SearchBar;
