import React from "react";
import "./Items.css";
import { Slide, Grow, Fade } from "@material-ui/core";

import cinamon from "../assets/cinamon1.png";
import flowers from "../assets/flowers.png";
import leaf from "../assets/leaf.png";
import mint from "../assets/mint.png";
import nut from "../assets/nut.png";
import orange from "../assets/orange.png";
import petals from "../assets/petals.png";
import seeds from "../assets/seeds.png";
import peach1 from "../assets/peach1.png";
import peach2 from "../assets/peach2.png";
import peach3 from "../assets/peach3.png";
import peachBranch from "../assets/peachBranch.png";
import peachFlower from "../assets/peachFlower.png";

function Item(props) {
  return (
    <div className={props.item.itemClass}>
      <h1 className="item__title">{props.item.name}</h1>
      <span className="item__description">{props.item.description}</span>
      {props.item.itemClass === "item1" && (
        <>
          <Slide in={true} direction="up" timeout={800}>
            <img src={cinamon} alt="cinamon" className="item1__cinamon" />
          </Slide>
          <img src={flowers} alt="flowers" className="item1__flowers" />
          <img src={petals} alt="petals" className="item1__petals" />
          <Fade in={true} timeout={1800}>
            <img src={leaf} alt="leaf" className="item1__leaf" />
          </Fade>
          <Grow in={true} timeout={1000}>
            <img src={mint} alt="mint" className="item1__mint" />
          </Grow>
          <Slide in={true} direction="down" timeout={1200}>
            <img src={nut} alt="nut" className="item1__nut" />
          </Slide>
          <Grow in={true} timeout={1400}>
            <img src={seeds} alt="seeds" className="item1__seeds" />
          </Grow>
          <Slide in={true} direction={"left"} timeout={1600}>
            <img src={orange} alt="orange" className="item1__orange" />
          </Slide>
        </>
      )}
      {props.item.itemClass === "item2" && (
        <>
          <Slide in={true} direction="up" timeout={1000}>
            <img src={peach3} alt="" className="peach3" />
          </Slide>
          <img src={peachFlower} alt="" className="peachFlower1" />
          <img src={peachFlower} alt="" className="peachFlower2" />
          <Slide in={true} direction="left" timeout={800}>
            <img src={peach2} alt="" className="peach2" />
          </Slide>
          <img src={peachBranch} alt="" className="peachBranch1" />

          <Slide in={true} direction="down" timeout={1100}>
            <img src={peach3} alt="" className="peach3-2" />
          </Slide>
          <Grow in={true} timeout={900}>
            <img src={peach1} alt="" className="peach1" />
          </Grow>
          <img src={peachBranch} alt="" className="peachBranch2" />
        </>
      )}
    </div>
  );
}

export default Item;
