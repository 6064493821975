import "./App.css";
import React, { useEffect, useState } from "react";
import { db } from "./data/firebase";
import { Header, Footer } from "./components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { Helmet } from "react-helmet";
import { Home, Checkout, Cart, Terms } from "./pages";
import { getProducts } from "./data/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getRates } from "./data/currencySlice";
import TeaItem from "./pages/teaItem/TeaItem";
import { selectCart } from "./data/cartSlice";
import About from "./pages/about/About";
import GivingBack from "./pages/givingBack/GivingBack";

function App() {
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const [showSearch, setShowSearch] = useState(false);
  const [searchBarClass, setSearchBarClass] = useState("searchBar__wrapper0");

  //get products
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      db.collection("products").onSnapshot((snapshot) => {
        dispatch(
          getProducts(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          )
        );
      });
      return () => (isMounted = false);
    }
  }, [dispatch]);

  //get exchange rate
  const { REACT_APP_EXCHANGE_APP_ID } = process.env;

  const BASE_URL = `https://openexchangerates.org/api/latest.json?app_id=${REACT_APP_EXCHANGE_APP_ID}`;

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetch(BASE_URL)
        .then((res) => res.json())
        .then((data) => dispatch(getRates(data.rates)));
    }
    return () => {
      isMounted = false;
    };
  }, [REACT_APP_EXCHANGE_APP_ID, BASE_URL, dispatch]);

  //close search bar
  const offClick = () => {
    setSearchBarClass("searchBar__wrapper0");
    setTimeout(() => () => setShowSearch(false), 500);
  };

  //local Storage
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        <Header
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          searchBarClass={searchBarClass}
          setSearchBarClass={setSearchBarClass}
        />
        <div className="app__body" onClick={showSearch ? offClick : undefined}>
          <Switch>
            <Route path="/cart">
              <Helmet>
                <title>Cart</title>
                <meta
                  name="description"
                  content="Review your shopping cart before paying."
                />
              </Helmet>
              <Cart />
            </Route>
            <Route path="/about">
              <Helmet>
                <title>About</title>
                <meta
                  name="description"
                  content="At The Happy Teacup, we value customer satisfaction, 
                which is why we only offer you the very best. 
                No mater where in the world you are, we believe you deserve 
                to taste tea at its finest. We only sell loose-leaf tea to reduce 
                our environmental footprint. Not only it is better for the planet, 
                but it’s also better for your wallet. A 100g package of tea can 
                make between 30 and 40 cups of tea!
                Another one of our priorities is to use our platform for good, 
                which is why we are dedicated to donating 10% of all profits to 
                charity. You can learn more about the charity we are currently 
                supporting here. Our delicious teas are shipped from India user Fedex. 
                Depending on where you are located, 
                shipping can take from a few days to a few weeks.
                "
                />
              </Helmet>
              <About />
            </Route>
            <Route path="/giving-back">
              <Helmet>
                <title>Giving Back</title>
                <meta
                  name="description"
                  content="We at The Happy Teacup believe it 
                is our responsiblity to use our platform for good. 
                We therefore are commited to donating 
                10% of all our profits to charity that align with our values. 
                We are still a reletively small store, but hope that as sales 
                increase, we will be able to raise our donations to 15% percent 
                or more.

                At the moment, we are supporting Lighthouse, a Japanese non-profit 
                organisation working to eliminate human trafficking, especially 
                sex trafficking, in Japan.

                Based in Tokyo, Lighthouse fights against human trafficking by:
                Providing confidential consultation services for survivors of 
                human trafficking.
                Educating and training law enforcement and government 
                officials to recognize human trafficking survivors and 
                investigate cases.
                Organizing awareness campaigns and seminars to help 
                bring more attention to the issue of human 
                trafficking and encourage change in society.
                Lobbing the government to change legislation
                To learn more about Lighthouse, you can visit 
                their website here.
                If you are involved with or know of a non-profit 
                organisation that aligns with our values of love, kindness, 
                and compassion for all living beings, we'd love to hear from you. 
                To get in touch, please click the link in the footer."
                />
              </Helmet>
              <GivingBack />
            </Route>
            <Route path="/tea/:id" exact component={TeaItem}></Route>
            <Route path="/terms">
              <Helmet>
                <title>Terms</title>
                <meta
                  name="description"
                  content="The Happy Teacup terms and conditions."
                />
              </Helmet>
              <Terms />
            </Route>
            <Route path="/checkout">
              <Helmet>
                <title>Checkout</title>
                <meta
                  name="description"
                  content="Just fill out your billing and shipping information and you'll be sipping delicious tea in no time."
                />
              </Helmet>
              <Checkout />
            </Route>

            <Route path="/">
              <Helmet>
                <title>The Happy Teacup</title>
                <meta
                  name="description"
                  content="Taste happiness in a cup thanks to our delicious variety of high-quality tea. We have something for everyone, from herbal teas, to chai, to organic."
                />
              </Helmet>
              <Home />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
