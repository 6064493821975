import React from "react";
import "./Cart.css";
import CartProduct from "./CartProduct";
import { useSelector } from "react-redux";
import { selectCurrency, selectRate } from "./../../data/currencySlice";
import { selectCart } from "./../../data/cartSlice";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function Cart() {
  const history = useHistory();
  const cart = useSelector(selectCart);
  const totalCartItems = cart.totalCartItems;
  const cartTotal = cart.cartTotal;
  const itemS = cart.items.length > 1 ? "items" : "item";
  const currency = useSelector(selectCurrency);
  const rate = useSelector(selectRate);

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
  });

  const emptyAlert = () => {
    confirmAlert({
      title: "Your cart is empty!",
      message: (
        <p className="confirmText">
          Add something your cart before proceeding to checkout
        </p>
      ),
      buttons: [
        {
          label: "Keep shopping",
          onClick: () => history.push("/"),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleClick = () => {
    if (cart.items.length === 0) {
      emptyAlert();
    } else {
      history.push("/checkout");
    }
  };

  return (
    <div className="cart">
      <div className="pages__top">&nbsp;</div>
      <div className="cartContainer">
        <div className="cart__cart">
          <div className="cart__top">
            <div className="cart__title">
              <h2>Your Shopping Cart</h2>
            </div>
            <div className="cart__subtotal">
              <p className="cart__subtotalTop">
                {`Subtotal (${totalCartItems} ${itemS}): `}
                <strong>{formatter.format(cartTotal * rate)}</strong>
              </p>
              <button className="cart__button" onClick={handleClick}>
                Checkout
              </button>
            </div>
          </div>
          <div className="cart__bottom">
            {cart.items.map(({ id, name, quantity, price, image }) => (
              <CartProduct
                id={id}
                key={id}
                name={name}
                quantity={quantity}
                price={price}
                image={image}
                formatter={formatter}
                rate={rate}
              />
            ))}
          </div>
          <div className="cart__subtotalWhite">
            <p className="cart__subtotalTop">
              {`Subtotal (${totalCartItems} ${itemS}): `}
              <strong>{formatter.format(cartTotal * rate)}</strong>
            </p>
          </div>
          <div className="cart__subtotalMb">
            <button className="cart__button" onClick={handleClick}>
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
