import { createSlice } from "@reduxjs/toolkit";

export const currencySlice = createSlice({
  name: "currency",
  initialState: {
    currency: "USD",
    sign: "$",
    rate: 1,
  },
  reducers: {
    changeCurrency: (state, action) => {
      state.currency = action.payload;
    },
    changeSign: (state, action) => {
      state.sign = action.payload;
    },
    getRates: (state, action) => {
      state.rates = action.payload;
    },
    changeRate: (state, action) => {
      state.rate = action.payload;
    },
  },
});

export const { changeCurrency, changeSign, getRates, changeRate } =
  currencySlice.actions;

export const selectCurrency = (state) => state.currency.currency;
export const selectSign = (state) => state.currency.sign;
export const selectRates = (state) => state.currency.rates;
export const selectRate = (state) => state.currency.rate;

export default currencySlice.reducer;
