import React, { useState, useEffect } from "react";
import { Typography, Divider } from "@material-ui/core";
import emailjs from "emailjs-com";
import {
  CardElement,
  ElementsConsumer,
  // useStripe,
} from "@stripe/react-stripe-js";
import Review from "./Review";
import { useDispatch } from "react-redux";
import "./Forms.css";
import { resetCart, selectCart } from "../../data/cartSlice";
import { useSelector } from "react-redux";
import { apiInstance } from "./../../utils/index";
import { selectCurrency } from "../../data/currencySlice";
import { selectRate } from "../../data/currencySlice";

import { LinearProgress } from "@material-ui/core";

function PaymentForm({ backStep, shippingData, discounts, nextStep }) {
  const cart = useSelector(selectCart);
  const dispatch = useDispatch();
  const rate = useSelector(selectRate);
  const currency = useSelector(selectCurrency).toLowerCase();
  //const stripe = useStripe();

  const [billingData, setBillingData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [percentOff, setPercentOff] = useState(0);
  const [cartString, setCartString] = useState("");
  const [showError, setShowError] = useState(false);
  const [noCart, setNoCart] = useState(false);

  const freeShippingUSD = 50;
  const freeShipping = freeShippingUSD;
  const shippingUSD = cart.cartTotal > freeShipping ? 0 : 6.99;
  const shipping = shippingUSD;
  const totalUSD = cart.cartTotal + shipping;
  const discount = (totalUSD - shipping) * (percentOff / 100);
  const totalToCharge =
    currency === "jpy"
      ? Math.round((totalUSD - discount) * rate)
      : Math.round((totalUSD - discount) * rate * 100);

  useEffect(() => {
    if (shippingData.billingCountry === "") {
      setBillingData({
        address: shippingData.address,
        city: shippingData.city,
        country: shippingData.shippingCountry,
        firstName: shippingData.firstName,
        lastName: shippingData.lastName,
        postalCode: shippingData.postalCode,
        state: shippingData.state,
      });
    } else {
      setBillingData({
        address: shippingData.billingAddress,
        city: shippingData.billingCity,
        country: shippingData.billingCountry,
        firstName: shippingData.billingFirstName,
        lastName: shippingData.billingLastName,
        postalCode: shippingData.billingPostalCode,
        state: shippingData.billingState,
      });
    }
  }, [shippingData]);

  useEffect(() => {
    cart.items.forEach((item) => {
      setCartString((cartString) =>
        cartString.concat(
          item.name + ", " + item.weight + "g, quantity: " + item.quantity + "/"
        )
      );
    });
    // eslint-disable-next-line
  }, []);

  const templateParams = {
    from_name: shippingData.firstName + " " + shippingData.lastName,
    message: cartString,
    from_email: shippingData.email,
    shippingData:
      shippingData.address +
      " " +
      shippingData.city +
      " " +
      shippingData.state +
      " " +
      shippingData.shippingCountry +
      " " +
      shippingData.postalCode,
  };

  const templateParams2 = {
    message: cartString,
    to_name: shippingData.firstName,
    to_email: shippingData.email,
  };

  //emailJS
  const {
    REACT_APP_SERVICE_ID,
    REACT_APP_TEMPLATE_ID1,
    REACT_APP_TEMPLATE_ID2,
    REACT_APP_USER_ID,
  } = process.env;

  const SERVICE_ID = REACT_APP_SERVICE_ID;
  const TEMPLATE_ID1 = REACT_APP_TEMPLATE_ID1;
  const TEMPLATE_ID2 = REACT_APP_TEMPLATE_ID2;
  const USER_ID = REACT_APP_USER_ID;

  const sendEmailToBuyer = () => {
    emailjs.send(SERVICE_ID, TEMPLATE_ID2, templateParams2, USER_ID);
  };

  const sendEmailToSeller = () => {
    emailjs.send(SERVICE_ID, TEMPLATE_ID1, templateParams, USER_ID).then(
      () => {
        setIsLoading(false);
        dispatch(resetCart({ items: [], totalCartItems: 0, cartTotal: 0 }));
        nextStep();
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const handleSubmit = async (e, elements, stripe) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    if (cart.items.length === 0) {
      setNoCart(true);
      return;
    }

    setIsLoading(true);
    const cardElement = elements.getElement("card");

    apiInstance
      .post("/payments/create", {
        amount: totalToCharge,
        currency: currency,
        email: shippingData.email,
        shipping: {
          name: shippingData.firstName + " " + shippingData.lastName,
          address: {
            city: shippingData.city,
            country: shippingData.shippingCountry,
            postal_code: shippingData.postalCode,
            state: shippingData.state,
            line1: shippingData.address,
          },
        },
      })
      .then(({ data: clientSecret }) => {
        stripe
          .createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              name: billingData.firstName + " " + billingData.lastName,
              address: {
                city: billingData.city,
                country: billingData.country,
                line1: billingData.address,
                postal_code: billingData.postalCode,
                state: billingData.state,
              },
            },
          })
          .then(({ paymentMethod }) => {
            if (paymentMethod !== undefined) {
              stripe
                .confirmCardPayment(clientSecret, {
                  payment_method: paymentMethod.id,
                })
                .then(({ paymentIntent }) => {
                  if (paymentIntent === undefined) {
                    setShowError(true);
                    setIsLoading(false);
                  } else {
                    sendEmailToBuyer();
                    sendEmailToSeller();
                  }
                });
            } else if (paymentMethod === undefined) {
              setShowError(true);
              setIsLoading(false);
            }
          });
      });
  };

  const configCardElement = {
    hidePostalCode: true,
  };

  return (
    <div className="paymentForm">
      <Review
        freeShipping={freeShipping}
        shipping={shipping}
        total={totalUSD}
        discounts={discounts}
        percentOff={percentOff}
        setPercentOff={setPercentOff}
        discount={discount}
      />
      <Divider className="divider" />
      <Typography variant="h5" gutterBottom className="paymentTitle">
        Payment method
      </Typography>

      <ElementsConsumer>
        {({ elements, stripe }) => (
          <form
            onSubmit={(e) => handleSubmit(e, elements, stripe)}
            className="elementConsumer"
          >
            <CardElement options={configCardElement} />
            {showError && (
              <p className="paymentForm__error">
                Oops! Something went wrong. Please double check your credit card
                information and try again.{" "}
              </p>
            )}
            {noCart && (
              <p className="paymentForm__error">
                It looks like your cart is empty! Please add something to your
                cart before paying.{" "}
              </p>
            )}
            <br /> <br />
            <div className="paymentForm__buttons">
              <button className="goBackButton" onClick={backStep}>
                Back
              </button>
              <button disabled={!stripe} type="submit" className="nextButton">
                Pay
              </button>
            </div>
          </form>
        )}
      </ElementsConsumer>

      {isLoading === true && <LinearProgress />}
    </div>
  );
}

export default PaymentForm;
