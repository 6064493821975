import React, { useState, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import Item from "./Item";
import "./Home.css";
import Categories from "./Categories";
import Products from "./Products";

function Home(props) {
  const [category, setCategory] = useState("category1");

  const productsRef = useRef();

  const scrollToProducts = () => {
    productsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  var items = [
    {
      name: "The Happy Teacup",
      description: <p>Taste happiness, one sip at&nbsp;a&nbsp;time</p>,
      itemClass: "item1",
    },
    {
      name: "Feeling Peachy?",
      description: <p>Try our new Lychee Peach Tea</p>,
      itemClass: "item2",
    },
    // {
    //   name: "Herbal Teas",
    //   description: "Relax and refresh, cafeine-free.",
    //   itemClass: "item3",
    // },
  ];
  return (
    <div>
      <Carousel className="carousel">
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
      <div className="home__bottom">
        <div className="home__button">
          <button className="bigButton" onClick={scrollToProducts}>
            Explore
          </button>
        </div>
        <div ref={productsRef}>
          <Categories category={category} setCategory={setCategory} />
        </div>
        <Products category={category} />
      </div>
    </div>
  );
}

export default Home;
