import React from "react";
import { useHistory } from "react-router-dom";
import "./AboutBottom.css";
import EmojiFoodBeverageTwoToneIcon from "@material-ui/icons/EmojiFoodBeverageTwoTone";
import LocalFloristTwoToneIcon from "@material-ui/icons/LocalFloristTwoTone";

function AboutBottom() {
  const history = useHistory();
  return (
    <div className="aboutBottom">
      <div className="aboutBottomContainer">
        <h2 className="aboutTitle">About Us</h2>
        <p className="aboutText">
          At The Happy Teacup, we value customer satisfaction, which is why we
          only offer you the very best. No matter where in the world you are, we
          believe you deserve to taste tea at its finest. We only sell
          loose-leaf tea to reduce our environmental footprint. Not only it is
          better for the planet, but it’s also better for your wallet. A 100g
          package of tea can make between 30 and 40 cups of tea!
        </p>

        <LocalFloristTwoToneIcon color="secondary" fontSize="large" />
        <p className="aboutText">
          Another one of our priorities is to use our platform for good, which
          is why we are dedicated to donating 10% of all profits to charity. You
          can learn more about the charity we are currently supporting{" "}
          <span
            className="aboutLink"
            onClick={() => history.push("/giving-back")}
          >
            here
          </span>
          .
        </p>
        <EmojiFoodBeverageTwoToneIcon color="secondary" fontSize="large" />

        <p className="aboutText">
          Our delicious teas are shipped from India using Fedex. Depending on
          where you are located, shipping can take from a few days to a few
          weeks.
        </p>
      </div>
    </div>
  );
}

export default AboutBottom;
