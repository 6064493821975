import React from "react";
import "./Footer.css";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Mailchimp from "../mailchimp/Mailchimp";

function Footer() {
  const history = useHistory();

  const contactAlert = () => {
    confirmAlert({
      title: "Get in Touch!",
      message: (
        <p className="confirmText">
          Clicking "continue" will open your default email application
        </p>
      ),
      buttons: [
        {
          label: "Continue",
          onClick: () => window.open("mailto:info@thehappyteacup.com"),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  return (
    <div className="footer">
      <div className="footer__top">
        <Mailchimp />
      </div>
      <div className="footer__bottom">
        <div className="footer__left">
          <p>© 2021 The Happy Teacup</p>
        </div>
        <div className="footer__right">
          <p onClick={() => history.push("/terms")} className="footer__link">
            Terms
          </p>
          <p className="footer__link" onClick={contactAlert}>
            Contact
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
