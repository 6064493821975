import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCart } from "./../../data/cartSlice";
import { selectCurrency, selectRate } from "./../../data/currencySlice";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
//import { loadStripe } from "@stripe/stripe-js";

function Review({
  freeShipping,
  shipping,
  total,
  discounts,
  percentOff,
  setPercentOff,
  discount,
}) {
  const cart = useSelector(selectCart);
  const rate = useSelector(selectRate);
  const currency = useSelector(selectCurrency);
  const [codeIsWrong, setCodeIsWrong] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [codeInput, setCodeInput] = useState("");

  useEffect(() => {
    discounts.forEach((discount) => {
      let stopLoop = false;
      if (stopLoop === false) {
        if (
          discount.data.code !== codeInput &&
          codeIsWrong === false &&
          codeInput !== ""
        ) {
          setCodeIsWrong(true);
          setHelperText("Invalid code");
          setPercentOff(0);
        } else if (discount.data.code === codeInput && codeIsWrong === true) {
          setCodeIsWrong(false);
          setHelperText("");
          setPercentOff(discount.data.percentOff);
          stopLoop = true;
        } else if (codeInput === "") {
          setCodeIsWrong(false);
          setPercentOff(0);
          stopLoop = true;
        }
      }
      return stopLoop;
    });
    //eslint-disable-next-line
  }, [codeInput, discounts]);

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
  });

  return (
    <div className="review">
      <Typography variant="h5" gutterBottom className="paymentTitle">
        Order Summary
      </Typography>
      <List disablePadding>
        {cart.items !== undefined &&
          cart.items.length > 0 &&
          cart.items.map((item) => (
            <ListItem key={item.id}>
              <ListItemText
                primary={item.name}
                secondary={`Quantity: ${item.quantity}`}
              />
              <Typography>
                {formatter.format(item.price * item.quantity * rate)}
              </Typography>
            </ListItem>
          ))}
        <ListItem key="2">
          <ListItemText
            primary="Shipping"
            secondary={`Free shipping on orders over ${formatter.format(
              freeShipping * rate
            )}`}
          />
          <Typography>{formatter.format(shipping * rate)}</Typography>
        </ListItem>
        <ListItem key="3">
          <TextField
            value={codeInput}
            onChange={(e) => setCodeInput(e.target.value)}
            id="discountCode"
            label="Discount Code"
            variant="outlined"
            error={codeIsWrong}
            helperText={helperText}
          />
        </ListItem>
        <ListItem key="4">
          <ListItemText
            className="review__codeText"
            secondary="* Please type discount code. Do not copy-paste."
          />
        </ListItem>
        {percentOff !== 0 && (
          <ListItem key="5">
            <ListItemText
              primary="Discount"
              secondary={`${percentOff}% off with discount code ${codeInput}`}
            />
            <Typography variant="subtitle1">
              {formatter.format(discount * rate)}
            </Typography>
          </ListItem>
        )}
        <ListItem key="6">
          <ListItemText primary="Total" />
          <Typography variant="subtitle1">
            {formatter.format((total - discount) * rate)}
          </Typography>
        </ListItem>
      </List>
    </div>
  );
}

export default Review;
