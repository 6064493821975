import React from "react";
import { CheckoutForm } from "../../components";

function Checkout() {
  return (
    <div className="checkout">
      <div className="pages__top">&nbsp;</div>
      <CheckoutForm />
    </div>
  );
}

export default Checkout;
