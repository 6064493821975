import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB2vlH3AlyQCW_WUPXmwN0zn3-tWZQPUfo",
  authDomain: "teatime-10d5c.firebaseapp.com",
  projectId: "teatime-10d5c",
  storageBucket: "teatime-10d5c.appspot.com",
  messagingSenderId: "815148032989",
  appId: process.env.REACT_APP_FIREBASE_API,
  measurementId: "G-R1Q36KTKQ3",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };
export default firebase;
