import React, { useState, useEffect } from "react";
import "./TabBoard.css";
import img2 from "../../../pages/assets/petals.png";
import img3 from "../../../pages/assets/flowers.png";
function TabBoard({ description, ingredients }) {
  const [text, setText] = useState("");
  const [tab1Class, setTab1Class] = useState("tab1On");
  const [tab2Class, setTab2Class] = useState("tab2Off");

  useEffect(() => {
    if (description !== "") {
      setText(description);
    }
  }, [description]);

  const setTabTo1 = () => {
    setText(description);
    setTab1Class("tab1On");
    setTab2Class("tab2Off");
  };

  const setTabTo2 = () => {
    setText(ingredients);
    setTab1Class("tab1Off");
    setTab2Class("tab2On");
  };

  return (
    <div className="tabBoard">
      <div className="tabBoard__tabs">
        <div className={tab1Class}>
          <h3 className="tabBoard__tab" onClick={setTabTo1}>
            Description
          </h3>
        </div>
        <div className={tab2Class}>
          <h3 className="tabBoard__tab" onClick={setTabTo2}>
            Ingredients
          </h3>
        </div>
      </div>
      <div className="tabBoard__text">
        <p>{text}</p>
      </div>
      <img src={img2} alt="petals" className="tabBoard__img1" />
      <img src={img3} alt="flowers" className="tabBoard__img2" />
    </div>
  );
}

export default TabBoard;
