import React, { useState, useEffect } from "react";
import "./Header.css";
import { MenuItem, Menu, Badge, Button } from "@material-ui/core";
//import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import logo from "../assets/tea-svg2.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrency,
  changeSign,
  selectCurrency,
  changeRate,
  selectRates,
} from "./../../data/currencySlice";
import useScrollPosition from "@react-hook/window-scroll";
import { selectCart, setTotalCartItems } from "./../../data/cartSlice";
import { Link, useHistory } from "react-router-dom";
import SearchBar from "./SearchBar";
import HeaderMenu from "./HeaderMenu";
import logoMb from "../../images/svglogo.svg";

function Header({
  showSearch,
  setShowSearch,
  searchBarClass,
  setSearchBarClass,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const currency = useSelector(selectCurrency);
  const cart = useSelector(selectCart);
  const totalCartItems = cart.totalCartItems;
  const rates = useSelector(selectRates);

  const [anchorEl, setAnchorEl] = useState(null);
  const [colorHeader, setColorHeader] = useState(false);
  //const [showMenu, setShowMenu] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [burgerClass, setBurgerClass] = useState("");

  const scrollY = useScrollPosition(60);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      let newCartTotal = 0;
      if (cart.items !== undefined && cart.items.length > 0) {
        cart.items.map((item) => {
          newCartTotal = newCartTotal + item.quantity;
          return newCartTotal;
        });
      }
      dispatch(setTotalCartItems(newCartTotal));
    }
    return () => (mounted = false);
  }, [cart.items, dispatch]);

  //headerColor on Scroll
  useEffect(() => {
    if (scrollY > 200 && !colorHeader) {
      setColorHeader(true);
    } else if (scrollY < 200 && colorHeader) {
      setColorHeader(false);
    }
  }, [scrollY, colorHeader]);

  //change currency
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeCurrency = (currency, sign) => {
    dispatch(changeCurrency(currency));
    dispatch(changeSign(sign));
    dispatch(changeRate(rates[currency]));
    handleClose();
  };

  const handleShowMenu = () => {
    setOpenDrawer(!openDrawer);
    setBurgerClass(burgerClass === "open" ? "" : "open");
  };

  return (
    <div className="header">
      <div className="header__top">
        <Link to="/giving-back" className="header__charity">
          10% of profits to go selected charities
        </Link>
        <p className="header__charityMb">10% to charity, always</p>
        <div className="header__topRight">
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {currency}
          </Button>
          <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleChangeCurrency("USD", "$")}
              value="USD"
            >
              USD
            </MenuItem>
            <MenuItem
              onClick={() => handleChangeCurrency("EUR", "€")}
              value="EUR"
            >
              EUR
            </MenuItem>
            <MenuItem
              onClick={() => handleChangeCurrency("GBP", "£")}
              value="GBP"
            >
              GBP
            </MenuItem>
            <MenuItem
              onClick={() => handleChangeCurrency("AUD", "AU$‎")}
              value="AUD"
            >
              AUD
            </MenuItem>
            <MenuItem
              onClick={() => handleChangeCurrency("CHF", "Fr.")}
              value="CHF"
            >
              CHF
            </MenuItem>
            <MenuItem
              onClick={() => handleChangeCurrency("BRL", "R$‎")}
              value="BRL"
            >
              BRL
            </MenuItem>
            <MenuItem
              onClick={() => handleChangeCurrency("CAD", "CA$")}
              value="CAD"
            >
              CAD
            </MenuItem>
            <MenuItem
              onClick={() => handleChangeCurrency("NZD", "NZ$")}
              value="NZD"
            >
              NZP
            </MenuItem>
            <MenuItem
              onClick={() => handleChangeCurrency("JPY", "‎¥")}
              value="JPY"
            >
              JPY
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="header__bottomContainer">
        <div className={colorHeader ? `header__bottomColor` : `header__bottom`}>
          <div className="header__bottomLeft">
            <img
              src={logo}
              alt="The Happy Teacup logo"
              className="logo"
              onClick={() => history.push("/")}
            />
            <img
              src={logoMb}
              alt="The Happy Teacup logo"
              className="logoMb"
              onClick={() => history.push("/")}
            />
          </div>

          <div className="header__bottomRight">
            <p
              className="header__option header__p"
              onClick={() => history.push("/")}
            >
              Store
            </p>
            <p
              className="header__option header__p"
              onClick={() => history.push("/giving-back")}
            >
              Giving Back
            </p>
            <p
              className="header__option header__p"
              onClick={() => history.push("/about")}
            >
              {" "}
              About
            </p>

            <SearchBar
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              searchBarClass={searchBarClass}
              setSearchBarClass={setSearchBarClass}
            />
            {/* <AccountCircleIcon className="header__option" /> */}
            <Badge badgeContent={totalCartItems}>
              <ShoppingCartIcon
                className="header__option"
                onClick={() => history.push("/cart")}
              />
            </Badge>

            <div id="nav-icon" onClick={handleShowMenu} className={burgerClass}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <HeaderMenu
              history={history}
              openDrawer={openDrawer}
              handleShowMenu={handleShowMenu}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
