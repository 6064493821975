import { createSlice } from "@reduxjs/toolkit";
import { arrayRemove } from "../utils/helperFunctions";

const initialState =
  JSON.parse(localStorage.getItem("cart")) === null
    ? {
        items: [],
        totalCartItems: 0,
        cartTotal: 0,
      }
    : JSON.parse(localStorage.getItem("cart"));

export const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    addToCart: (state, action) => {
      state.items.push(action.payload);
      state.cartTotal += action.payload.price * action.payload.quantity;
    },
    removeFromCart: (state, action) => {
      state.items.forEach((item) => {
        if (item.id === action.payload) {
          state.items = arrayRemove(state.items, item);
          state.cartTotal -= item.price * item.quantity;
          return state;
        }
      });
    },
    upQuantity: (state, action) => {
      state.items.forEach((item) => {
        if (item.id === action.payload.id) {
          item.quantity += action.payload.ammount;
          state.cartTotal += action.payload.ammount * item.price;
          return state;
        }
      });
    },
    setTotalCartItems: (state, action) => {
      state.totalCartItems = action.payload;
    },
    downQuantity: (state, action) => {
      state.items.forEach((item) => {
        if (item.id === action.payload.id) {
          item.quantity -= action.payload.ammount;
          state.cartTotal -= action.payload.ammount * item.price;
          return item.quantity;
        }
      });
    },
    resetCart: (state, action) => {
      state.items = action.payload.items;
      state.totalCartItems = action.payload.totalCartItems;
      state.cartTotal = action.payload.cartTotal;
    },
  },
});

// export const getCartTotal = (cart) =>
//   cart?.reduce((amount, item) => item.price + amount, 0);

export const {
  addToCart,
  removeFromCart,
  upQuantity,
  downQuantity,
  setTotalCartItems,
  resetCart,
} = cartSlice.actions;

export const selectCart = (state) => state.cart;

export default cartSlice.reducer;
