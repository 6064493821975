import React from "react";
import GivingBackLeft from "./GivingBackLeft";
import GivingBackRight from "./GivingBackRight";
import "./GivingBack.css";
import leaf from "../assets/leaf.png";
import seeds from "../assets/seeds.png";

function GivingBack() {
  return (
    <div className="givingBack">
      <div className="pages__top">&nbsp;</div>
      <div className="gbContainer">
        <GivingBackLeft />
        <GivingBackRight />
      </div>
      <img src={leaf} alt="" className="gbLeaf" />
      <img src={seeds} alt="" className="gbSeeds" />
    </div>
  );
}

export default GivingBack;
