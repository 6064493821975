import React, { useEffect, useState } from "react";
import Product from "../../product/Product";
import "./RelatedProducts.css";
import { is_touch_device } from "../../../utils/helperFunctions";

function RelatedProducts({ products, type, id }) {
  const [relatedArray, setRelatedArray] = useState([]);
  const [touch, setTouch] = useState(false);
  const singleType = type.split(" ")[0];

  useEffect(() => {
    setTouch(is_touch_device());
    return () => {};
  }, []);

  useEffect(() => {
    let newArray = [];
    if (products !== undefined) {
      products.forEach((product) => {
        if (
          product.data.type.includes(singleType) &&
          type !== "" &&
          product.id !== id &&
          id !== ""
        ) {
          if (newArray.length < 5) {
            newArray.push(product);
          }
        }
      });
    }
    setRelatedArray(newArray);

    // eslint-disable-next-line
  }, [products, type, singleType, id]);

  return (
    <div className="relatedProducts">
      <h2 className="relatedProducts__title">Related Products</h2>
      <div className="relatedProducts__body">
        {relatedArray.map(
          ({ id, data: { name, priceUSD, weight, image, type } }) => (
            <Product
              touch={touch}
              key={id}
              id={id}
              name={name}
              price={priceUSD}
              weight={weight}
              image={image}
            />
          )
        )}
      </div>
    </div>
  );
}

export default RelatedProducts;
