import React, { useState, useEffect } from "react";
import "./TeaItem.css";
import { selectProducts } from "../../data/productsSlice";
import { useSelector } from "react-redux";
import RelatedProducts from "../../components/teaItem/relatedProducts/RelatedProducts";
import TabBoard from "../../components/teaItem/tabBoard/TabBoard";
import TeaInfo from "../../components/teaItem/teaInfo/TeaInfo";
import img1 from "../assets/cinamon1.png";
import { Helmet } from "react-helmet";

function TeaItem() {
  const products = useSelector(selectProducts);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [weight, setWeight] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [priceUSD, setPriceUSD] = useState("");
  const [image, setImage] = useState("");
  const [id, setId] = useState("");
  const [type, setType] = useState("");

  const teaNameUrl = window.location.href.includes("thehappyteacup")
    ? window.location.href
        .replace("https://www.thehappyteacup.com/tea/", "")
        .replace(/-/g, " ")
    : window.location.href
        .replace("http://localhost:3000/tea/", "")
        .replace(/-/g, " ");

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (products !== undefined) {
        products.forEach((product) => {
          if (product.data.name.toLowerCase() === teaNameUrl) {
            setName(product.data.name);
            setDescription(product.data.description);
            setWeight(product.data.weight);
            setIngredients(product.data.ingredients);
            setPriceUSD(product.data.priceUSD);
            setImage(product.data.image);
            setId(product.id);
            setType(product.data.type);
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [products, teaNameUrl]);

  return (
    <div className="teaItem">
      <Helmet>
        <title>{name}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="pages__top">&nbsp;</div>
      <div className="teaItem__container">
        <TeaInfo
          name={name}
          image={image}
          weight={weight}
          priceUSD={priceUSD}
          id={id}
        />
        <TabBoard description={description} ingredients={ingredients} />

        <RelatedProducts products={products} type={type} id={id} />
        <img src={img1} alt="cinnamon" className="teaItem__img1" />
      </div>
    </div>
  );
}

export default TeaItem;
