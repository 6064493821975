import React, { useState } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import countriesList from "./countriesList.js";
import "./Forms.css";
import FormInput from "./FormInput";
import { useHistory } from "react-router-dom";

function AddressForm({ next }) {
  const history = useHistory();

  const [shippingCountry, setShippingCountry] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [addressIsSame, setAddressIsSame] = useState(false);
  const shippingCountries = countriesList;

  const methods = useForm();
  return (
    <div className="addressForm">
      <Typography variant="h5" gutterBottom>
        Shipping Address
      </Typography>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) =>
            next({ ...data, shippingCountry, billingCountry })
          )}
        >
          <Grid container spacing={3} className="grid">
            <FormInput
              name="firstName"
              label="First name"
              className="formInput"
            />
            <FormInput
              name="lastName"
              label="Last name"
              className="formInput"
            />
            <FormInput
              name="email"
              label="Contact Email"
              className="formInput"
            />
            <FormInput
              name="postalCode"
              label="ZIP / Post Code"
              className="formInput"
            />
            <FormInput name="address" label="Address" className="formInput" />
            <FormInput name="city" label="City" className="formInput" />
            <FormInput
              name="state"
              label="State/region"
              className="formInput"
            />
            <Grid item xs={12} sm={6}>
              <InputLabel>Shipping Country</InputLabel>
              <Select
                required
                value={shippingCountry}
                fullWidth
                onChange={(e) => setShippingCountry(e.target.value)}
              >
                {shippingCountries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Typography variant="h5" gutterBottom>
            Billing Address
          </Typography>
          <div className="checkboxDiv">
            <Checkbox
              value="checkedA"
              inputProps={{ "aria-label": "Checkbox A" }}
              color="default"
              onClick={() => setAddressIsSame(!addressIsSame)}
              className="checkBox"
            />
            <p className="radioText">Same as shipping address</p>
          </div>

          {addressIsSame === false && (
            <div>
              <Grid container spacing={3}>
                <FormInput name="billingFirstName" label="First name" />
                <FormInput name="billingLastName" label="Last name" />
                <FormInput
                  name="billingPostalCode"
                  label="Billing ZIP / Post Code"
                />
                <FormInput name="billingAddress" label="Address" />
                <FormInput name="billingCity" label="City" />
                <FormInput name="billingState" label="State/region" />

                <Grid item xs={12} sm={6}>
                  <InputLabel>Billing Country</InputLabel>
                  <Select
                    required
                    value={billingCountry}
                    fullWidth
                    onChange={(e) => setBillingCountry(e.target.value)}
                  >
                    {shippingCountries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </div>
          )}

          <div className="addressForm__buttons">
            <button
              className="goBackButton"
              onClick={() => history.push("/cart")}
            >
              Back&nbsp;to&nbsp;cart
            </button>
            <button className="nextButton" type="submit">
              Next
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default AddressForm;
