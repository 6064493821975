import React, { useState } from "react";
import "./TeaInfo.css";
import Counter from "../../counter/Counter";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, selectCart, upQuantity } from "../../../data/cartSlice";
import { selectCurrency, selectRate } from "../../../data/currencySlice";
import ShoppingCartTwoToneIcon from "@material-ui/icons/ShoppingCartTwoTone";
import AddTwoToneIcon from "@material-ui/icons/AddTwoTone";

function TeaInfo({ name, image, priceUSD, weight, id }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const intPrice = parseInt(priceUSD);
  const cart = useSelector(selectCart);
  const rate = useSelector(selectRate);
  const currency = useSelector(selectCurrency);

  const [quantity, setQuantity] = useState(1);

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
  });

  const quantityDown = () => {
    if (quantity > 1) {
      let newQuantity = quantity;
      newQuantity -= 1;
      setQuantity(newQuantity);
    }
  };

  const handleAddToCart = () => {
    let isInCart = false;
    cart.items.map((item) => {
      if (item.id === id) {
        dispatch(
          upQuantity({
            id: item.id,
            ammount: quantity,
          })
        );
        isInCart = true;
      }
      return isInCart;
    });
    if (isInCart === false) {
      dispatch(
        addToCart({
          id: id,
          name: name,
          weight: weight,
          price: intPrice,
          image: image,
          quantity: quantity,
        })
      );
    }
  };

  const handleBuyNow = () => {
    handleAddToCart();
    history.push("/checkout");
  };

  return (
    <div className="teaInfo">
      <img src={image} alt={name} className="teaInfo__img" />
      <div className="teaInfo__right">
        <h2 className="teaInfo__title">{name}</h2>
        <div className="teaInfo__counterPrice">
          <p className="teaInfo__price">{`${formatter.format(
            priceUSD * rate
          )}/${weight}g`}</p>
          <Counter
            quantity={quantity}
            handleClickPlus={() => setQuantity(quantity + 1)}
            handleClickMinus={quantityDown}
          />
        </div>

        <div className="teaInfo__buttons">
          <button
            className="bigButton teaInfo__button1"
            onClick={handleAddToCart}
          >
            <p className="teaInfo__buttonTextPC">Add to cart</p>
            <AddTwoToneIcon className="teaInfo__buttonIcons" />{" "}
            <ShoppingCartTwoToneIcon className="teaInfo__buttonIcons" />
          </button>
          <button
            className="bigButtonWhite teaInfo__button2"
            onClick={handleBuyNow}
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default TeaInfo;
