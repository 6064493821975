import React from "react";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import "./Confirmation.css";

function Confirmation({ title, text, returnLink, returnText }) {
  const history = useHistory();

  return (
    <div className="confirmation">
      {console.log("confirmation")}
      <ScrollToTop />
      <p>{text}</p>
      <button onClick={() => history.push(returnLink)}>{returnText}</button>
    </div>
  );
}

export default Confirmation;
