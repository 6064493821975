import React from "react";
import "./Counter.css";

function Counter({ quantity, handleClickPlus, handleClickMinus }) {
  return (
    <div className="counter">
      <p className="counter__option" onClick={handleClickPlus}>
        +
      </p>
      <p className="counter__quantity">{quantity}</p>
      <p className="counter__option" onClick={handleClickMinus}>
        -
      </p>
    </div>
  );
}

export default Counter;
