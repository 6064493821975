import React, { useState, useEffect } from "react";
import { Paper, Stepper, Step, StepLabel } from "@material-ui/core";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Confirmation from "../confirmation/Confirmation";
import "./CheckoutForm.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { db } from "../../data/firebase";

function CheckoutForm() {
  const [activeStep, setActiveStep] = useState(0);
  const [shippingData, setShippingData] = useState({});
  const steps = ["Shipping address", "Payment details"];
  const [discounts, setDiscounts] = useState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      db.collection("discounts").onSnapshot((snapshot) => {
        setDiscounts(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
    }
    return () => (isMounted = false);
  }, []);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const nextStep = () => {
    setActiveStep((previousActiveStep) => previousActiveStep + 1);
  };
  const backStep = () => {
    setActiveStep((previousActiveStep) => previousActiveStep - 1);
  };

  const next = (data) => {
    setShippingData(data);
    nextStep();
  };

  const Form = () =>
    activeStep === 0 ? (
      <AddressForm next={next} />
    ) : (
      <Elements stripe={stripePromise}>
        <PaymentForm
          backStep={backStep}
          shippingData={shippingData}
          discounts={discounts}
          nextStep={nextStep}
        />
      </Elements>
    );
  return (
    <div className="checkoutForm">
      <Paper className="checkoutForm__paper">
        <h2 className="checkoutForm__title">Checkout</h2>
        <Stepper activeStep={activeStep} className="stepper">
          {steps.map((step) => (
            <Step key={step} className="step">
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Confirmation
            title="Payment Complete"
            text="Thank you for your order!"
            returnLink="/"
            returnText="Keep shopping"
          />
        ) : (
          <Form />
        )}
      </Paper>
    </div>
  );
}

export default CheckoutForm;
