import { SwipeableDrawer } from "@material-ui/core";
import React from "react";
import "./HeaderMenu.css";
import orange from "../../pages/assets/orange.png";
import leaf from "../../pages/assets/leaf.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import AccessibilityIcon from "@material-ui/icons/Accessibility";

function HeaderMenu({ handleShowMenu, history, openDrawer }) {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <div className="headerMenu" onClick={handleShowMenu}>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onOpen={() => console.log("")}
        onClose={handleShowMenu}
        anchor="top"
      >
        <div className="swipableContainer">
          <div className="innerMenu">
            <h3 className="swipableMenuItem" onClick={() => history.push("/")}>
              {" "}
              <HomeIcon />
              &nbsp;Home
            </h3>
            <h3
              className="swipableMenuItem"
              onClick={() => history.push("/about")}
            >
              <InfoIcon />
              &nbsp;About
            </h3>
            <h3
              className="swipableMenuItem"
              onClick={() => history.push("/giving-back")}
            >
              <AccessibilityIcon />
              &nbsp;Giving back
            </h3>
            <a href="https://www.facebook.com/search/top?q=the%20happy%20teacup">
              <h3 className="swipableMenuItem">
                <FacebookIcon />
                &nbsp; Like our page!
              </h3>
            </a>
          </div>
        </div>
        <img src={leaf} alt="leaf" className="swipableleaf" />
        <img src={orange} alt="orange" className="swipableOrange" />
      </SwipeableDrawer>
    </div>
  );
}

export default HeaderMenu;
