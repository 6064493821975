import React, { useState } from "react";

import "./Categories.css";

function Categories({ setCategory }) {
  const [option1Class, setOption1Class] = useState("categories__optionOn");
  const [option2Class, setOption2Class] = useState("categories__optionOff");
  const [option3Class, setOption3Class] = useState("categories__optionOff");
  const [option4Class, setOption4Class] = useState("categories__optionOff");

  const option1Pink = () => {
    setOption1Class("categories__optionOn");
    setOption2Class("categories__optionOff");
    setOption3Class("categories__optionOff");
    setOption4Class("categories__optionOff");
    setCategory("category1");
  };

  const option2Pink = () => {
    setOption1Class("categories__optionOff");
    setOption2Class("categories__optionOn");
    setOption3Class("categories__optionOff");
    setOption4Class("categories__optionOff");
    setCategory("category2");
  };

  const option3Pink = () => {
    setOption1Class("categories__optionOff");
    setOption2Class("categories__optionOff");
    setOption3Class("categories__optionOn");
    setOption4Class("categories__optionOff");
    setCategory("category3");
  };

  const option4Pink = () => {
    setOption1Class("categories__optionOff");
    setOption2Class("categories__optionOff");
    setOption3Class("categories__optionOff");
    setOption4Class("categories__optionOn");
    setCategory("category4");
  };
  return (
    <div className="categories">
      <div className="categories__options">
        <h2 className={option1Class} onClick={option1Pink}>
          Specialty
        </h2>
        <h2 className={option2Class} onClick={option2Pink}>
          Herbal
        </h2>
        <h2 className={option3Class} onClick={option3Pink}>
          Chai
        </h2>
        <h2 className={option4Class} onClick={option4Pink}>
          Organic
        </h2>
      </div>
    </div>
  );
}

export default Categories;
