import React, { useState } from "react";
import "./Product.css";
import { selectRate, selectCurrency } from "./../../data/currencySlice";
import { useSelector, useDispatch } from "react-redux";
import { Paper } from "@material-ui/core";
import { selectCart, addToCart, upQuantity } from "./../../data/cartSlice";
import Counter from "../counter/Counter";
import { useHistory } from "react-router-dom";

function Product({ name, weight, price, image, id, touch }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const currency = useSelector(selectCurrency);
  const rate = useSelector(selectRate);
  const cart = useSelector(selectCart);
  //const intPrice = parseInt(price);
  const [quantity, setQuantity] = useState(1);

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
  });

  const handleAddToCart = (e) => {
    e.stopPropagation();
    let isInCart = false;
    cart.items.map((item) => {
      if (item.id === id) {
        dispatch(
          upQuantity({
            id: item.id,
            ammount: quantity,
          })
        );
        isInCart = true;
      }
      return isInCart;
    });
    if (isInCart === false) {
      dispatch(
        addToCart({
          id: id,
          name: name,
          weight: weight,
          price: price,
          image: image,
          quantity: quantity,
        })
      );
    }
  };

  const quantityDown = () => {
    if (quantity > 1) {
      let newQuantity = quantity;
      newQuantity -= 1;
      setQuantity(newQuantity);
    }
  };

  return (
    <Paper className="product">
      <img src={image} className="product__img" alt="tea" />
      <div className="product__bottom">
        <h3 className="product__name">{name}</h3>
        <p className="product__price">{`${formatter.format(
          price * rate
        )} / ${weight}g`}</p>
      </div>
      <div
        to={`/tea/${name.toLowerCase().replace(/\s/g, "-")}`}
        className={
          touch ? "product__btnWrapperTouch" : "product__buttonWrapper"
        }
        onClick={() =>
          history.push(`/tea/${name.toLowerCase().replace(/\s/g, "-")}`)
        }
      >
        <button
          className={touch ? "product__buttonTouch" : "product__button"}
          onClick={handleAddToCart}
        >
          Add to Cart
        </button>
      </div>
      <div className="product__counter">
        <Counter
          quantity={quantity}
          handleClickPlus={() => setQuantity(quantity + 1)}
          handleClickMinus={quantityDown}
        />
      </div>
    </Paper>
  );
}

export default Product;
