import React from "react";
import "./Mailchimp.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const MailchimpFormContainer = (props) => {
  const postUrl = `https://live.us6.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  const SimpleForm = () => <MailchimpSubscribe url={postUrl} />;
  return (
    <div className="mailchimp__formContainer">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <div className="mc__form">
            <p className="mc__formLabel">Subscribe to our newsletter!</p>
            <SimpleForm
              onSubmitted={(formData) => subscribe(formData)}
              className="mc__inputContainer"
            />
            {status === "sending" && (
              <div className="mc__sending">sending...</div>
            )}
            {status === "error" && (
              <div
                className="mc__error"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === "success" && (
              <div className="mc__success">Subscribed !</div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default MailchimpFormContainer;
