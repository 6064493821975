import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
//import userReducer from "./userSlice";
import productReducer from "./productsSlice";
import currencyReducer from "./currencySlice";
import cartReducer from "./cartSlice";

const reducer = combineReducers({
  products: productReducer,
  currency: currencyReducer,
  cart: cartReducer,
});

const store = configureStore({
  reducer,
});

export default store;
